import { FunctionComponent } from "react";
import Name3 from "./Name3";
import GenderOptionContainer from "./GenderOptionContainer";
import styles from "./Details5.module.css";

export type Details5Type = {
  className?: string;
};

const Details5: FunctionComponent<Details5Type> = ({ className = "" }) => {
  return (
    <div className={[styles.details, className].join(" ")}>
      <div className={styles.title}>Applicant Details</div>
      <div className={styles.passengerName}>
        <div className={styles.name}>
          <Name3
            labelName="Name *"
            enterTextHerePlaceholder="Enter your full name"
            propTop="0px"
            propLeft="0px"
          />
          <GenderOptionContainer
            gender="Gender *"
            rectangle41253="/rectangle-41253.svg"
            femaleIndicator="/vector.svg"
          />
          <div className={styles.name1}>
            <div className={styles.labelName}>Date of Birth*</div>
            <div className={styles.frameParent}>
              <input
                className={styles.frameChild}
                placeholder="MM / DD / YY"
                type="text"
              />
              <div className={styles.calendar}>
                <img className={styles.vectorIcon} alt="" src="/vector-1.svg" />
              </div>
            </div>
          </div>
          <Name3
            labelName="Aadhar Card Number *"
            enterTextHerePlaceholder="xxxx xxxx xxxx"
            propTop="103px"
            propLeft="325px"
          />
          <Name3
            labelName="Father/Guardian’s Name*"
            enterTextHerePlaceholder="Enter father/guardian’s name"
            propTop="203px"
            propLeft="0px"
          />
        </div>
      </div>
    </div>
  );
};

export default Details5;
