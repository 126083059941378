import { FunctionComponent } from "react";
import styles from "./Cards.module.css";

export type CardsType = {
  className?: string;
  group1000013608?: string;
  generalBusTicket?: string;
  reliableDailyTravelForEve?: string;
};

const Cards: FunctionComponent<CardsType> = ({
  className = "",
  group1000013608,
  generalBusTicket,
  reliableDailyTravelForEve,
}) => {
  return (
    <div className={[styles.cards, className].join(" ")}>
      <div className={styles.popularPassCardContentParent}>
        <div className={styles.popularPassCardContent}>
          <div className={styles.infographics}>
            <img
              className={styles.infographicsChild}
              loading="lazy"
              alt=""
              src={group1000013608}
            />
            <div className={styles.infographicsItem} />
          </div>
        </div>
        <div className={styles.generalBusTicketWrapper}>
          <h2 className={styles.generalBusTicket}>{generalBusTicket}</h2>
        </div>
        <div className={styles.reliableDailyTravel}>
          {reliableDailyTravelForEve}
        </div>
      </div>
      <div className={styles.buttonsWrapper}>
        <button className={styles.buttons}>
          <div className={styles.searchBus}>Apply Now</div>
        </button>
      </div>
    </div>
  );
};

export default Cards;
