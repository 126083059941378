import { FunctionComponent } from "react";
import styles from "./Details.module.css";

export type DetailsType = {
  className?: string;
};

const Details: FunctionComponent<DetailsType> = ({ className = "" }) => {
  return (
    <div className={[styles.details, className].join(" ")}>
      <div className={styles.title}>Document Upload</div>
      <div className={styles.passengerName}>
        <div className={styles.name}>
          <div className={styles.name1}>
            <div className={styles.uploadApplicantsPassport}>
              Upload applicant’s passport size photo*:
            </div>
            <div className={styles.imageTabWrapper}>
              <div className={styles.imageTab}>
                <input
                  className={styles.img23499200jpg}
                  placeholder="IMG 23499200.jpg"
                  type="text"
                />
                <img
                  className={styles.actionIcon}
                  alt=""
                  src="/action-icon.svg"
                />
              </div>
            </div>
          </div>
          <button className={styles.buttons}>
            <div className={styles.searchBus}>Choose file</div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Details;
