import { FunctionComponent } from "react";
import styles from "./BusPassOthersCards.module.css";

export type BusPassOthersCardsType = {
  className?: string;
};

const BusPassOthersCards: FunctionComponent<BusPassOthersCardsType> = ({
  className = "",
}) => {
  return (
    <div className={[styles.busPassOthersCards, className].join(" ")}>
      <div className={styles.imageWeb}>
        <img className={styles.picturesIcon} alt="" src="/pictures@2x.png" />
      </div>
      <div className={styles.imageMobile}>
        <img className={styles.picturesIcon1} alt="" src="/pictures-1@2x.png" />
      </div>
      <div className={styles.cardTextHolder}>
        <div className={styles.texts}>
          <h2 className={styles.studentPass}>MLAs, MPs, MLCs and Spouses</h2>
          <div
            className={styles.affordableTravelPasses}
          >{`Lorem ipsum dolor sit amet, adipiscing elit. sed do eiusmod tempor incididunt ut. `}</div>
        </div>
        <div className={styles.lineSeparater}>
          <img
            className={styles.lineSeparatorIcon}
            alt=""
            src="/line-separator.svg"
          />
        </div>
        <div className={styles.buttonHolder}>
          <div className={styles.buttons}>
            <div className={styles.searchBus}>Apply</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusPassOthersCards;
