import { FunctionComponent } from "react";
import styles from "./FrameComponent.module.css";

export type FrameComponentType = {
  className?: string;
  cityGeneralCommuterPass?: string;
};

const FrameComponent: FunctionComponent<FrameComponentType> = ({
  className = "",
  cityGeneralCommuterPass,
}) => {
  return (
    <div
      className={[styles.passTypeOptionSelectionWrapper, className].join(" ")}
    >
      <div className={styles.passTypeOptionSelection}>
        <img
          className={styles.radioButtonIcon}
          loading="lazy"
          alt=""
          src="/radio-button.svg"
        />
        <div className={styles.cityGeneralCommuter}>
          {cityGeneralCommuterPass}
        </div>
      </div>
    </div>
  );
};

export default FrameComponent;
