import { FunctionComponent } from "react";
import styles from "./PickupAndDropPoints.module.css";

export type PickupAndDropPointsType = {
  className?: string;
};

const PickupAndDropPoints: FunctionComponent<PickupAndDropPointsType> = ({
  className = "",
}) => {
  return (
    <div className={[styles.pickupAndDropPoints, className].join(" ")}>
      <img
        className={styles.pickupAndDropPointsChild}
        alt=""
        src="/vector-234251230.svg"
      />
      <button className={styles.buttons}>
        <div className={styles.searchBus}>Confirm</div>
      </button>
    </div>
  );
};

export default PickupAndDropPoints;
