import { FunctionComponent, useMemo, type CSSProperties } from "react";
import styles from "./PassApplication.module.css";

export type PassApplicationType = {
  className?: string;
  passApplications?: string;
  tGSRTCOffersARangeOfBusPa?: string;
  searchBus?: string;

  /** Style props */
  propMinWidth?: CSSProperties["minWidth"];
};

const PassApplication: FunctionComponent<PassApplicationType> = ({
  className = "",
  passApplications,
  tGSRTCOffersARangeOfBusPa,
  searchBus,
  propMinWidth,
}) => {
  const passApplicationStyle: CSSProperties = useMemo(() => {
    return {
      minWidth: propMinWidth,
    };
  }, [propMinWidth]);

  return (
    <div
      className={[styles.passApplication, className].join(" ")}
      style={passApplicationStyle}
    >
      <div className={styles.container}>
        <h2 className={styles.passApplications}>{passApplications}</h2>
        <div className={styles.tgsrtcOffersA}>{tGSRTCOffersARangeOfBusPa}</div>
      </div>
      <button className={styles.buttons}>
        <div className={styles.searchBus}>{searchBus}</div>
      </button>
    </div>
  );
};

export default PassApplication;
