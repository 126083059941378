import { FunctionComponent } from "react";
import Header from "../components/Header";
import FrameComponent1 from "../components/FrameComponent1";
import Cards from "../components/Cards";
import BusPassOtherPassesContai from "../components/BusPassOtherPassesContai";
import styles from "./BusPassHome.module.css";

const BusPassHome: FunctionComponent = () => {
  return (
    <div className={styles.busPassHome}>
      <Header />
      <FrameComponent1 />
      <section className={styles.passesContainer}>
        <div className={styles.popularPassesContainerParent}>
          <div className={styles.popularPassesContainer}>
            <h2 className={styles.heading}>Popular Pass Options</h2>
          </div>
          <div className={styles.busPassPopularCards}>
            <Cards
              group1000013608="/group-1000013608@2x.png"
              generalBusTicket="General Bus Ticket"
              reliableDailyTravelForEve=" Reliable daily travel for everyone. Easy and economical bus rides."
            />
            <Cards
              group1000013608="/group-1000013608-1@2x.png"
              generalBusTicket="Monthly Season Ticket"
              reliableDailyTravelForEve="Monthly passes for frequent travellers, 30 days journey with only 20 days fare."
            />
            <Cards
              group1000013608="/group-1000013608-2@2x.png"
              generalBusTicket="Pushpak Pass"
              reliableDailyTravelForEve="Lorem ipsum dolor sit amet, adipiscing elit. sed do eiusmod tempor incididunt ut.  adipiscing elit. sed do eiusmod."
            />
          </div>
        </div>
      </section>
      <BusPassOtherPassesContai />
    </div>
  );
};

export default BusPassHome;
