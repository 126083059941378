import { FunctionComponent } from "react";
import styles from "./GenderOptionContainer.module.css";

export type GenderOptionContainerType = {
  className?: string;
  gender?: string;
  rectangle41253?: string;
  femaleIndicator?: string;
};

const GenderOptionContainer: FunctionComponent<GenderOptionContainerType> = ({
  className = "",
  gender,
  rectangle41253,
  femaleIndicator,
}) => {
  return (
    <div className={[styles.genderOptionContainer, className].join(" ")}>
      <div className={styles.gender}>{gender}</div>
      <div className={styles.passengerSelectionParent}>
        <div className={styles.passengerSelection}>
          <img
            className={styles.passengerSelectionChild}
            alt=""
            src="/rectangle-41201.svg"
          />
          <img
            className={styles.unionIcon}
            loading="lazy"
            alt=""
            src="/union.svg"
          />
          <div className={styles.male}>Male</div>
        </div>
        <div className={styles.passengerSelection1}>
          <img
            className={styles.passengerSelectionItem}
            alt=""
            src={rectangle41253}
          />
          <div className={styles.femaleIndicatorWrapper}>
            <img
              className={styles.femaleIndicatorIcon}
              loading="lazy"
              alt=""
              src={femaleIndicator}
            />
          </div>
          <div className={styles.female}>Female</div>
        </div>
        <div className={styles.passengerSelection2}>
          <div className={styles.passengerSelectionInner}>
            <img
              className={styles.frameChild}
              loading="lazy"
              alt=""
              src="/group-1000014270.svg"
            />
          </div>
          <img
            className={styles.rectangleIcon}
            alt=""
            src="/rectangle-41254.svg"
          />
          <div className={styles.others}>Others</div>
        </div>
      </div>
    </div>
  );
};

export default GenderOptionContainer;
