import { FunctionComponent } from "react";
import Header2 from "../components/Header2";
import MiniBanner from "../components/MiniBanner";
import Details2 from "../components/Details2";
import Details1 from "../components/Details1";
import Details from "../components/Details";
import Name1 from "../components/Name1";
import styles from "./BusPassTextActiveFields.module.css";

const BusPassTextActiveFields: FunctionComponent = () => {
  return (
    <div className={styles.busPassTextActiveFields}>
      <Header2 />
      <MiniBanner />
      <main className={styles.passengerDetailsWrapper}>
        <form className={styles.passengerDetails}>
          <div className={styles.details}>
            <div className={styles.title}>Pass Type Information</div>
            <div className={styles.passengerName}>
              <div className={styles.name}>
                <div className={styles.name1}>
                  <div className={styles.labelName}>City*</div>
                  <div className={styles.imageInputContainer}>
                    <div className={styles.enterTextHere}>Hyderabad</div>
                    <img className={styles.icons} alt="" src="/icons2.svg" />
                  </div>
                </div>
                <div className={styles.name2}>
                  <div className={styles.labelName1}>Pass type*</div>
                  <div className={styles.searchInputContainer}>
                    <a className={styles.enterTextHere1}>Metro Express</a>
                    <img className={styles.icons1} alt="" src="/icons2.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Details2 />
          <Details1 />
          <Details />
          <div className={styles.details1}>
            <div className={styles.title1}>
              <div className={styles.title2}>Contact Details</div>
              <div className={styles.theDetailsOf}>
                The details of the status of your application will be sent to
                the details shared below:
              </div>
            </div>
            <div className={styles.passengerName1}>
              <div className={styles.name3}>
                <Name1
                  labelName="Mobile Number *"
                  enterTextHerePlaceholder="9883647892"
                />
                <Name1
                  labelName="Email ID "
                  enterTextHerePlaceholder="radhika@gmail.com"
                  propFlex="1"
                  propMinWidth="120px"
                  propWidth="unset"
                />
              </div>
            </div>
          </div>
          <footer className={styles.buttonsWrapper}>
            <button className={styles.buttons}>
              <div className={styles.searchBus}>Confirm Details</div>
            </button>
          </footer>
        </form>
      </main>
    </div>
  );
};

export default BusPassTextActiveFields;
