import { FunctionComponent } from "react";
import MiniBanner from "../components/MiniBanner";
import PassengerDetails3 from "../components/PassengerDetails3";
import PassengerDetails2 from "../components/PassengerDetails2";
import PassengerDetails1 from "../components/PassengerDetails1";
import PassengerDetails from "../components/PassengerDetails";
import styles from "./BusPassReviewDetailsWithou.module.css";

const BusPassReviewDetailsWithou: FunctionComponent = () => {
  return (
    <div className={styles.busPassReviewDetailsWithou}>
      <header className={styles.header}>
        <div className={styles.container}>
          <img
            className={styles.gamyamLogoIcon}
            loading="lazy"
            alt=""
            src="/gamyam-logo@2x.png"
          />
          <div className={styles.tabContainer}>
            <div className={styles.navTabs}>
              <a className={styles.reservations}>Home</a>
            </div>
            <div className={styles.navTabs1}>
              <a className={styles.reservations1}>Tracking</a>
            </div>
            <div className={styles.navTabs2}>
              <a className={styles.reservations2}>Passes</a>
            </div>
            <div className={styles.navTabs3}>
              <a className={styles.reservations3}>Logistics</a>
            </div>
            <div className={styles.navTabs4}>
              <a className={styles.reservations4}>Tourism</a>
            </div>
            <div className={styles.navTabs5}>
              <a className={styles.reservations5}>Bus on Contract</a>
            </div>
            <div className={styles.navTabs6}>
              <a className={styles.reservations6}>Enquiries</a>
            </div>
          </div>
        </div>
        <div className={styles.sideContent}>
          <div className={styles.button}>
            <div className={styles.buttons}>
              <a className={styles.text}>Log In/ Sign Up</a>
            </div>
          </div>
        </div>
      </header>
      <MiniBanner />
      <main className={styles.passengerDetailsWrapper}>
        <section className={styles.passengerDetails}>
          <div className={styles.details}>
            <div className={styles.titleParent}>
              <div className={styles.title}>Review your details</div>
              <div className={styles.passDetails}>
                <div className={styles.title1}>
                  <div className={styles.passDetails1}>Pass Details</div>
                  <img
                    className={styles.titleChild}
                    loading="lazy"
                    alt=""
                    src="/vector-234251220.svg"
                  />
                </div>
                <div className={styles.passFields}>
                  <div className={styles.name}>
                    <div className={styles.city}>City</div>
                    <div className={styles.hyderabad}>Hyderabad</div>
                  </div>
                  <div className={styles.age}>
                    <div className={styles.passType}>Pass Type</div>
                    <div className={styles.metroExpress}>Metro Express</div>
                  </div>
                </div>
              </div>
            </div>
            <PassengerDetails3 />
            <PassengerDetails2 />
            <PassengerDetails1 />
            <PassengerDetails />
            <div className={styles.titleGroup}>
              <div className={styles.title2}>
                <img
                  className={styles.titleItem}
                  alt=""
                  src="/vector-234251220-5.svg"
                />
              </div>
              <div className={styles.checkBoxAndLink}>
                <div className={styles.checkBox}>
                  <input className={styles.checkBoxdefault} type="checkbox" />
                </div>
                <div className={styles.iConfirmThat}>
                  I confirm that the details provided above are accurate
                </div>
              </div>
              <div className={styles.checkBoxAndLink1}>
                <div className={styles.checkBox1}>
                  <input className={styles.checkBoxdefault1} type="checkbox" />
                </div>
                <div className={styles.iAgreeToContainer}>
                  <span>{`I agree to TGSRTC’s `}</span>
                  <span className={styles.termsAndConditions}>
                    Terms and Conditions
                  </span>
                </div>
              </div>
            </div>
          </div>
          <footer className={styles.bottomButton}>
            <div className={styles.buttons1}>
              <div className={styles.searchBus}>Proceed to Pay Rs. 1130</div>
            </div>
          </footer>
        </section>
      </main>
    </div>
  );
};

export default BusPassReviewDetailsWithou;
