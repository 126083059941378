import { FunctionComponent } from "react";
import styles from "./PopularPassesContainer.module.css";

export type PopularPassesContainerType = {
  className?: string;
};

const PopularPassesContainer: FunctionComponent<PopularPassesContainerType> = ({
  className = "",
}) => {
  return (
    <footer className={[styles.popularPassesContainer, className].join(" ")}>
      <div className={styles.busPassPopularCards}>
        <div className={styles.cards}>
          <div className={styles.popularPassContent}>
            <div className={styles.popularPassInfo}>
              <div className={styles.infographics}>
                <img
                  className={styles.infographicsChild}
                  loading="lazy"
                  alt=""
                  src="/group-10000136081@2x.png"
                />
                <div className={styles.illustrationShape} />
              </div>
            </div>
            <div className={styles.passTitleContainer}>
              <h2 className={styles.generalBusTicket}>General Bus Ticket</h2>
            </div>
            <div className={styles.reliableDailyTravel}>
              {" "}
              Reliable daily travel for everyone. Easy and economical bus rides.
            </div>
          </div>
          <div className={styles.popularPassButtonContainer}>
            <button className={styles.buttons}>
              <div className={styles.searchBus}>Apply Now</div>
            </button>
          </div>
        </div>
        <div className={styles.cards1}>
          <div className={styles.frameParent}>
            <div className={styles.infographicsWrapper}>
              <div className={styles.infographics1}>
                <img
                  className={styles.infographicsItem}
                  alt=""
                  src="/group-1000013608-11@2x.png"
                />
                <div className={styles.infographicsInner} />
              </div>
            </div>
            <div className={styles.monthlySeasonTicketWrapper}>
              <h2 className={styles.monthlySeasonTicket}>
                Monthly Season Ticket
              </h2>
            </div>
            <div className={styles.monthlyPassesFor}>
              Monthly passes for frequent travellers, 30 days journey with only
              20 days fare.
            </div>
          </div>
          <div className={styles.buttonsWrapper}>
            <button className={styles.buttons1}>
              <div className={styles.searchBus1}>Apply Now</div>
            </button>
          </div>
        </div>
        <div className={styles.cards2}>
          <div className={styles.frameGroup}>
            <div className={styles.infographicsContainer}>
              <div className={styles.infographics2}>
                <img
                  className={styles.groupIcon}
                  alt=""
                  src="/group-1000013608-21@2x.png"
                />
                <div className={styles.ellipseDiv} />
              </div>
            </div>
            <div className={styles.pushpakPassWrapper}>
              <h2 className={styles.pushpakPass}>Pushpak Pass</h2>
            </div>
            <div className={styles.loremIpsumDolor}>
              Lorem ipsum dolor sit amet, adipiscing elit. sed do eiusmod tempor
              incididunt ut. adipiscing elit. sed do eiusmod.
            </div>
          </div>
          <div className={styles.buttonsContainer}>
            <button className={styles.buttons2}>
              <div className={styles.searchBus2}>Apply Now</div>
            </button>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default PopularPassesContainer;
