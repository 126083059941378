import { FunctionComponent } from "react";
import styles from "./Name2.module.css";

export type Name2Type = {
  className?: string;
  enterTextHerePlaceholder?: string;
};

const Name2: FunctionComponent<Name2Type> = ({
  className = "",
  enterTextHerePlaceholder,
}) => {
  return (
    <div className={[styles.name, className].join(" ")}>
      <div className={styles.labelName}>Applicant’s Address *</div>
      <div className={styles.enterTextHereWrapper}>
        <input
          className={styles.enterTextHere}
          placeholder={enterTextHerePlaceholder}
          type="text"
        />
      </div>
    </div>
  );
};

export default Name2;
