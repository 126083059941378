import { FunctionComponent } from "react";
import BusPassOthersCards2 from "./BusPassOthersCards2";
import styles from "./BusPassOtherPassesContai.module.css";

export type BusPassOtherPassesContaiType = {
  className?: string;
};

const BusPassOtherPassesContai: FunctionComponent<
  BusPassOtherPassesContaiType
> = ({ className = "" }) => {
  return (
    <section className={[styles.busPassOtherPassesContai, className].join(" ")}>
      <h2 className={styles.otherAvailablePasses}>Other Available Passes</h2>
      <footer className={styles.busPassOtherCards}>
        <BusPassOthersCards2
          studentPass="Student Pass"
          affordableTravelPassesFor="Affordable travel passes for students. Convenient and budget-friendly commuting."
        />
        <BusPassOthersCards2
          studentPass="NGO Pass"
          affordableTravelPassesFor="Special bus passes supporting NGO workers' daily transportation needs."
        />
        <BusPassOthersCards2
          studentPass="Dialysis Pass"
          affordableTravelPassesFor="Free bus travel for dialysis patients, ensuring their regular treatment"
        />
        <BusPassOthersCards2
          studentPass="Divyang"
          affordableTravelPassesFor="Lorem ipsum dolor sit amet, adipiscing elit. sed do eiusmod tempor incididunt ut.  "
        />
        <BusPassOthersCards2
          studentPass="Monthly Season Tickets"
          affordableTravelPassesFor="Lorem ipsum dolor sit amet, adipiscing elit. sed do eiusmod tempor incididunt ut.  "
        />
        <BusPassOthersCards2
          studentPass="Journalists"
          affordableTravelPassesFor="Lorem ipsum dolor sit amet, adipiscing elit. sed do eiusmod tempor incididunt ut.  "
        />
        <BusPassOthersCards2
          studentPass="Freedom Fighters"
          affordableTravelPassesFor="Lorem ipsum dolor sit amet, adipiscing elit. sed do eiusmod tempor incididunt ut.  "
        />
        <div className={styles.busPassOthersCards}>
          <div className={styles.imageWeb}>
            <img
              className={styles.picturesIcon}
              alt=""
              src="/pictures@2x.png"
            />
          </div>
          <div className={styles.imageMobile}>
            <img
              className={styles.picturesIcon1}
              alt=""
              src="/pictures-1@2x.png"
            />
          </div>
          <div className={styles.cardTextHolder}>
            <div className={styles.texts}>
              <h2 className={styles.studentPass}>
                MLAs, MPs, MLCs and Spouses
              </h2>
              <div
                className={styles.affordableTravelPasses}
              >{`Lorem ipsum dolor sit amet, adipiscing elit. sed do eiusmod tempor incididunt ut. `}</div>
            </div>
            <div className={styles.lineSeparater}>
              <img
                className={styles.lineSeparatorIcon}
                alt=""
                src="/line-separator.svg"
              />
            </div>
            <div className={styles.buttonHolder}>
              <button className={styles.buttons}>
                <div className={styles.searchBus}>Apply</div>
              </button>
            </div>
          </div>
        </div>
      </footer>
    </section>
  );
};

export default BusPassOtherPassesContai;
