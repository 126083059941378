import { FunctionComponent } from "react";
import Header from "../components/Header";
import WelcomeCard from "../components/WelcomeCard";
import Cards from "../components/Cards";
import BusPassOthersCards1 from "../components/BusPassOthersCards1";
import BusPassOthersCards from "../components/BusPassOthersCards";
import styles from "./BusPassSelectedOverlay.module.css";

const BusPassSelectedOverlay: FunctionComponent = () => {
  return (
    <div className={styles.busPassSelectedOverlay}>
      <Header />
      <div className={styles.heading}>Popular Pass Options</div>
      <main className={styles.content}>
        <WelcomeCard />
        <section className={styles.cardsContainer}>
          <footer className={styles.popularCards}>
            <div className={styles.busPassPopularCards}>
              <Cards
                group1000013608="/group-1000013608@2x.png"
                generalBusTicket="General Bus Ticket"
                reliableDailyTravelForEve=" Reliable daily travel for everyone. Easy and economical bus rides."
              />
              <Cards
                group1000013608="/group-1000013608-11@2x.png"
                generalBusTicket="Monthly Season Ticket"
                reliableDailyTravelForEve="Monthly passes for frequent travellers, 30 days journey with only 20 days fare."
              />
              <Cards
                group1000013608="/group-1000013608-22@2x.png"
                generalBusTicket="Pushpak Pass"
                reliableDailyTravelForEve="Lorem ipsum dolor sit amet, adipiscing elit. sed do eiusmod tempor incididunt ut.  adipiscing elit. sed do eiusmod."
              />
            </div>
          </footer>
          <div className={styles.busPassOtherPassesContai}>
            <h2 className={styles.otherAvailablePasses}>
              Other Available Passes
            </h2>
            <div className={styles.busPassOtherCards}>
              <BusPassOthersCards1
                studentPass="Student Pass"
                affordableTravelPassesFor="Affordable travel passes for students. Convenient and budget-friendly commuting."
              />
              <BusPassOthersCards1
                studentPass="NGO Pass"
                affordableTravelPassesFor="Special bus passes supporting NGO workers' daily transportation needs."
              />
              <BusPassOthersCards1
                studentPass="Dialysis Pass"
                affordableTravelPassesFor="Free bus travel for dialysis patients, ensuring their regular treatment"
              />
              <BusPassOthersCards1
                studentPass="Divyang"
                affordableTravelPassesFor="Lorem ipsum dolor sit amet, adipiscing elit. sed do eiusmod tempor incididunt ut.  "
              />
              <BusPassOthersCards1
                studentPass="Monthly Season Tickets"
                affordableTravelPassesFor="Lorem ipsum dolor sit amet, adipiscing elit. sed do eiusmod tempor incididunt ut.  "
              />
              <BusPassOthersCards1
                studentPass="Journalists"
                affordableTravelPassesFor="Lorem ipsum dolor sit amet, adipiscing elit. sed do eiusmod tempor incididunt ut.  "
              />
              <BusPassOthersCards1
                studentPass="Freedom Fighters"
                affordableTravelPassesFor="Lorem ipsum dolor sit amet, adipiscing elit. sed do eiusmod tempor incididunt ut.  "
              />
              <BusPassOthersCards />
            </div>
          </div>
        </section>
        <section className={styles.overlayBg} />
      </main>
    </div>
  );
};

export default BusPassSelectedOverlay;
