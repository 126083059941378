import { FunctionComponent } from "react";
import PassApplication from "./PassApplication";
import styles from "./FrameComponent1.module.css";

export type FrameComponent1Type = {
  className?: string;
};

const FrameComponent1: FunctionComponent<FrameComponent1Type> = ({
  className = "",
}) => {
  return (
    <section className={[styles.welcomeCardWrapper, className].join(" ")}>
      <div className={styles.welcomeCard}>
        <img className={styles.imageIcon} alt="" src="/image@2x.png" />
        <div className={styles.text}>
          <h1 className={styles.title}>
            Stay on the move with easy pass applications and renewals!
          </h1>
          <div className={styles.description}>What are you here for?</div>
        </div>
        <div className={styles.passAndTrackBanner}>
          <PassApplication
            passApplications="Pass Applications"
            tGSRTCOffersARangeOfBusPa="TGSRTC offers a range of bus passes to cater to various passenger needs, apply now! "
            searchBus="Apply for a Pass"
            propMinWidth="365px"
          />
          <img
            className={styles.separatorIcon}
            loading="lazy"
            alt=""
            src="/separator.svg"
          />
          <PassApplication
            passApplications="Track Applications"
            tGSRTCOffersARangeOfBusPa="Already applied for a bus pass? Check your application status here."
            searchBus="Track my Application"
            propMinWidth="369px"
          />
        </div>
      </div>
    </section>
  );
};

export default FrameComponent1;
