import { FunctionComponent } from "react";
import styles from "./PassengerDetails2.module.css";

export type PassengerDetails2Type = {
  className?: string;
};

const PassengerDetails2: FunctionComponent<PassengerDetails2Type> = ({
  className = "",
}) => {
  return (
    <div className={[styles.passengerDetails, className].join(" ")}>
      <div className={styles.title}>
        <div className={styles.addressDetails}>Address Details</div>
        <img
          className={styles.titleChild}
          alt=""
          src="/vector-234251220-2.svg"
        />
      </div>
      <div className={styles.detailsParent}>
        <div className={styles.details}>
          <div className={styles.name}>
            <div className={styles.applicantsAddress}>Applicant’s Address:</div>
            <div className={styles.aSankalpColony}>
              18A, Sankalp Colony, Khairatabad, Hyderabad.
            </div>
          </div>
        </div>
        <div className={styles.age}>
          <div className={styles.district}>District:</div>
          <div className={styles.hyderabad}>Hyderabad</div>
        </div>
        <div className={styles.age1}>
          <div className={styles.mandal}>Mandal:</div>
          <div className={styles.hyderabad1}>Hyderabad</div>
        </div>
        <div className={styles.age2}>
          <div className={styles.villagecity}>Village/City:</div>
          <div className={styles.hyderabad2}>Hyderabad</div>
        </div>
        <div className={styles.age3}>
          <div className={styles.pincode}>Pincode:</div>
          <div className={styles.pincodeValue}>500004</div>
        </div>
      </div>
    </div>
  );
};

export default PassengerDetails2;
