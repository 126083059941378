import { FunctionComponent } from "react";
import styles from "./Header2.module.css";

export type Header2Type = {
  className?: string;
};

const Header2: FunctionComponent<Header2Type> = ({ className = "" }) => {
  return (
    <header className={[styles.header, className].join(" ")}>
      <div className={styles.container}>
        <img
          className={styles.gamyamLogoIcon}
          loading="lazy"
          alt=""
          src="/gamyam-logo@2x.png"
        />
        <div className={styles.tabContainer}>
          <div className={styles.navTabs}>
            <a className={styles.reservations}>Home</a>
          </div>
          <div className={styles.navTabs1}>
            <a className={styles.reservations1}>Tracking</a>
          </div>
          <div className={styles.navTabs2}>
            <a className={styles.reservations2}>Passes</a>
          </div>
          <div className={styles.navTabs3}>
            <a className={styles.reservations3}>Logistics</a>
          </div>
          <div className={styles.navTabs4}>
            <a className={styles.reservations4}>Tourism</a>
          </div>
          <div className={styles.navTabs5}>
            <a className={styles.reservations5}>Bus on Contract</a>
          </div>
          <div className={styles.navTabs6}>
            <a className={styles.reservations6}>Enquiries</a>
          </div>
        </div>
      </div>
      <div className={styles.buttonWrapper}>
        <div className={styles.button}>
          <div className={styles.buttons}>
            <a className={styles.text}>Log In/ Sign Up</a>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header2;
