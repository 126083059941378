import { FunctionComponent, useMemo, type CSSProperties } from "react";
import styles from "./Name3.module.css";

export type Name3Type = {
  className?: string;
  labelName?: string;
  enterTextHerePlaceholder?: string;

  /** Style props */
  propTop?: CSSProperties["top"];
  propLeft?: CSSProperties["left"];
};

const Name3: FunctionComponent<Name3Type> = ({
  className = "",
  labelName,
  enterTextHerePlaceholder,
  propTop,
  propLeft,
}) => {
  const nameStyle: CSSProperties = useMemo(() => {
    return {
      top: propTop,
      left: propLeft,
    };
  }, [propTop, propLeft]);

  return (
    <div className={[styles.name, className].join(" ")} style={nameStyle}>
      <div className={styles.labelName}>{labelName}</div>
      <div className={styles.genderInput}>
        <input
          className={styles.enterTextHere}
          placeholder={enterTextHerePlaceholder}
          type="text"
        />
      </div>
    </div>
  );
};

export default Name3;
