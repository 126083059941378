import { FunctionComponent } from "react";
import styles from "./BusPassOthersCards2.module.css";

export type BusPassOthersCards2Type = {
  className?: string;
  studentPass?: string;
  affordableTravelPassesFor?: string;
};

const BusPassOthersCards2: FunctionComponent<BusPassOthersCards2Type> = ({
  className = "",
  studentPass,
  affordableTravelPassesFor,
}) => {
  return (
    <div className={[styles.busPassOthersCards, className].join(" ")}>
      <div className={styles.imageWeb}>
        <img className={styles.picturesIcon} alt="" src="/pictures@2x.png" />
      </div>
      <div className={styles.imageMobile}>
        <img className={styles.picturesIcon1} alt="" src="/pictures-1@2x.png" />
      </div>
      <div className={styles.cardTextHolder}>
        <div className={styles.texts}>
          <h2 className={styles.studentPass}>
            <p className={styles.studentPass1}>{studentPass}</p>
          </h2>
          <div className={styles.affordableTravelPasses}>
            {affordableTravelPassesFor}
          </div>
        </div>
        <div className={styles.lineSeparater}>
          <img
            className={styles.lineSeparatorIcon}
            alt=""
            src="/line-separator.svg"
          />
        </div>
        <div className={styles.buttonHolder}>
          <button className={styles.buttons}>
            <div className={styles.searchBus}>Apply</div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default BusPassOthersCards2;
