import { FunctionComponent } from "react";
import Name3 from "./Name3";
import GenderOptionContainer from "./GenderOptionContainer";
import styles from "./Details2.module.css";

export type Details2Type = {
  className?: string;
};

const Details2: FunctionComponent<Details2Type> = ({ className = "" }) => {
  return (
    <div className={[styles.details, className].join(" ")}>
      <div className={styles.title}>Applicant Details</div>
      <div className={styles.passengerName}>
        <div className={styles.name}>
          <Name3
            labelName="Name *"
            enterTextHerePlaceholder="Enter your full name"
          />
          <GenderOptionContainer
            gender="Gender*"
            rectangle41253="/rectangle-412531.svg"
            femaleIndicator="/vector2.svg"
          />
          <div className={styles.name1}>
            <div className={styles.dateOfBirth}>Date of Birth *</div>
            <div className={styles.dateInputContainer}>
              <input
                className={styles.dateInputContainerChild}
                placeholder="19/ 05/ 1962"
                type="text"
              />
              <img
                className={styles.calendarblankIcon}
                alt=""
                src="/calendarblank.svg"
              />
            </div>
          </div>
          <Name3
            labelName="Aadhar Card Number *"
            enterTextHerePlaceholder="3977 0234 8743"
            propTop="103px"
            propLeft="325px"
          />
          <Name3
            labelName="Father/Guardian’s Name*"
            enterTextHerePlaceholder="Ravindra Chandrashekar"
            propTop="203px"
            propLeft="0px"
          />
        </div>
      </div>
    </div>
  );
};

export default Details2;
