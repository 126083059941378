import { FunctionComponent, useMemo, type CSSProperties } from "react";
import styles from "./Name1.module.css";

export type Name1Type = {
  className?: string;
  labelName?: string;
  enterTextHerePlaceholder?: string;

  /** Style props */
  propFlex?: CSSProperties["flex"];
  propMinWidth?: CSSProperties["minWidth"];
  propWidth?: CSSProperties["width"];
};

const Name1: FunctionComponent<Name1Type> = ({
  className = "",
  labelName,
  enterTextHerePlaceholder,
  propFlex,
  propMinWidth,
  propWidth,
}) => {
  const name1Style: CSSProperties = useMemo(() => {
    return {
      flex: propFlex,
      minWidth: propMinWidth,
      width: propWidth,
    };
  }, [propFlex, propMinWidth, propWidth]);

  return (
    <div className={[styles.name, className].join(" ")} style={name1Style}>
      <div className={styles.labelName}>{labelName}</div>
      <div className={styles.enterTextHereWrapper}>
        <input
          className={styles.enterTextHere}
          placeholder={enterTextHerePlaceholder}
          type="text"
        />
      </div>
    </div>
  );
};

export default Name1;
