import { FunctionComponent } from "react";
import styles from "./WelcomeCard.module.css";

export type WelcomeCardType = {
  className?: string;
};

const WelcomeCard: FunctionComponent<WelcomeCardType> = ({
  className = "",
}) => {
  return (
    <form className={[styles.welcomeCard, className].join(" ")}>
      <img className={styles.imageIcon} alt="" src="/image@2x.png" />
      <div className={styles.text}>
        <h1 className={styles.title}>
          Stay on the move with easy pass applications and renewals!
        </h1>
        <div className={styles.description}>What are you here for?</div>
      </div>
      <div className={styles.passAndTrackBanner}>
        <div className={styles.passApplication}>
          <div className={styles.container}>
            <h2 className={styles.passApplications}>Pass Applications</h2>
            <div
              className={styles.tgsrtcOffersA}
            >{`TGSRTC offers a range of bus passes to cater to various passenger needs, apply now! `}</div>
          </div>
          <div className={styles.buttons}>
            <div className={styles.searchBus}>Apply for a Pass</div>
          </div>
        </div>
        <img
          className={styles.separatorIcon}
          loading="lazy"
          alt=""
          src="/separator.svg"
        />
        <div className={styles.trackApplication}>
          <div className={styles.trackApplicationsParent}>
            <h2 className={styles.trackApplications}>Track Applications</h2>
            <div className={styles.alreadyAppliedFor}>
              Already applied for a bus pass? Check your application status
              here.
            </div>
          </div>
          <button className={styles.buttons1}>
            <div className={styles.searchBus1}>Track my Application</div>
          </button>
        </div>
      </div>
      <div className={styles.popUp}>
        <div className={styles.title1}>
          <h3 className={styles.title2}>
            Select the pass you want to apply for
          </h3>
          <div className={styles.closeIcon}>
            <img
              className={styles.actionIcon}
              loading="lazy"
              alt=""
              src="/action-icon.svg"
            />
          </div>
        </div>
        <div className={styles.titleSeparator}>
          <img
            className={styles.separaterIcon}
            loading="lazy"
            alt=""
            src="/separater.svg"
          />
        </div>
        <div className={styles.searchForm}>
          <div className={styles.searchPlaceForm}>
            <input
              className={styles.searchLabel}
              placeholder="Search for a pass type you want to apply for"
              type="text"
            />
            <img className={styles.icons} alt="" src="/icons.svg" />
          </div>
        </div>
        <div className={styles.radioOptions}>
          <div className={styles.radioOptions1}>
            <div className={styles.searchList}>
              <img
                className={styles.radioButtonIcon}
                alt=""
                src="/radio-button1.svg"
              />
              <div className={styles.studentPass}>Student Pass</div>
              <div className={styles.scroll}>
                <div className={styles.bar} />
                <div className={styles.progress} />
              </div>
            </div>
            <div className={styles.passTypes}>
              <div className={styles.searchList1}>
                <img
                  className={styles.radioButtonIcon1}
                  alt=""
                  src="/radio-button1.svg"
                />
                <div className={styles.generalCommuterPass}>
                  General Commuter Pass
                </div>
              </div>
              <div className={styles.searchList2}>
                <img
                  className={styles.radioButtonIcon2}
                  alt=""
                  src="/radio-button1.svg"
                />
                <div className={styles.pushpakPass}>Pushpak Pass</div>
              </div>
              <div className={styles.searchList3}>
                <img
                  className={styles.radioButtonIcon3}
                  alt=""
                  src="/radio-button1.svg"
                />
                <div className={styles.ngoPass}>NGO Pass</div>
              </div>
              <div className={styles.searchList4}>
                <img
                  className={styles.radioButtonIcon4}
                  alt=""
                  src="/radio-button1.svg"
                />
                <div className={styles.dialysisPass}>Dialysis Pass</div>
              </div>
              <div className={styles.searchList5}>
                <img
                  className={styles.radioButtonIcon5}
                  alt=""
                  src="/radio-button1.svg"
                />
                <div className={styles.physicallyChallengedPass}>
                  Physically Challenged Pass
                </div>
              </div>
            </div>
            <div className={styles.monthlyTicket}>
              <img
                className={styles.radioButtonIcon6}
                alt=""
                src="/radio-button1.svg"
              />
              <div className={styles.monthlySeasonTicket}>
                Monthly Season Ticket
              </div>
            </div>
          </div>
        </div>
        <div className={styles.searchFormButton}>
          <button className={styles.buttons2}>
            <div className={styles.searchBus2}>Create</div>
          </button>
        </div>
      </div>
    </form>
  );
};

export default WelcomeCard;
