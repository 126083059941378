import { FunctionComponent } from "react";
import Header2 from "../components/Header2";
import WelcomeCardContainer from "../components/WelcomeCardContainer";
import PopularPassesContainer from "../components/PopularPassesContainer";
import styles from "./BusPassInformationOverlay.module.css";

const BusPassInformationOverlay: FunctionComponent = () => {
  return (
    <div className={styles.busPassInformationOverlay}>
      <Header2 />
      <main className={styles.content}>
        <WelcomeCardContainer />
        <div className={styles.headingContainer}>
          <h2 className={styles.heading}>Popular Pass Options</h2>
        </div>
        <section className={styles.popularPassesContainerParent}>
          <PopularPassesContainer />
          <div className={styles.busPassOtherPassesContai}>
            <h2 className={styles.otherAvailablePasses}>
              Other Available Passes
            </h2>
            <div className={styles.busPassOtherCards}>
              <div className={styles.busPassOthersCards}>
                <div className={styles.imageWeb}>
                  <img
                    className={styles.picturesIcon}
                    alt=""
                    src="/pictures@2x.png"
                  />
                </div>
                <div className={styles.imageMobile}>
                  <img
                    className={styles.picturesIcon1}
                    alt=""
                    src="/pictures-1@2x.png"
                  />
                </div>
                <div className={styles.cardTextHolder}>
                  <div className={styles.texts}>
                    <h2 className={styles.studentPass}>
                      <p className={styles.studentPass1}>Student Pass</p>
                    </h2>
                    <div className={styles.affordableTravelPasses}>
                      Affordable travel passes for students. Convenient and
                      budget-friendly commuting.
                    </div>
                  </div>
                  <div className={styles.lineSeparater}>
                    <img
                      className={styles.lineSeparatorIcon}
                      alt=""
                      src="/line-separator.svg"
                    />
                  </div>
                  <div className={styles.buttonHolder}>
                    <div className={styles.buttons}>
                      <div className={styles.searchBus}>Apply</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.busPassOthersCards1}>
                <div className={styles.imageWeb1}>
                  <img
                    className={styles.picturesIcon2}
                    alt=""
                    src="/pictures@2x.png"
                  />
                </div>
                <div className={styles.imageMobile1}>
                  <img
                    className={styles.picturesIcon3}
                    alt=""
                    src="/pictures-1@2x.png"
                  />
                </div>
                <div className={styles.cardTextHolder1}>
                  <div className={styles.texts1}>
                    <h2 className={styles.studentPass2}>
                      <p className={styles.ngoPass}>NGO Pass</p>
                    </h2>
                    <div className={styles.affordableTravelPasses1}>
                      Special bus passes supporting NGO workers' daily
                      transportation needs.
                    </div>
                  </div>
                  <div className={styles.lineSeparater1}>
                    <img
                      className={styles.lineSeparatorIcon1}
                      alt=""
                      src="/line-separator.svg"
                    />
                  </div>
                  <div className={styles.buttonHolder1}>
                    <div className={styles.buttons1}>
                      <div className={styles.searchBus1}>Apply</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.busPassOthersCards2}>
                <div className={styles.imageWeb2}>
                  <img
                    className={styles.picturesIcon4}
                    alt=""
                    src="/pictures@2x.png"
                  />
                </div>
                <div className={styles.imageMobile2}>
                  <img
                    className={styles.picturesIcon5}
                    alt=""
                    src="/pictures-1@2x.png"
                  />
                </div>
                <div className={styles.cardTextHolder2}>
                  <div className={styles.texts2}>
                    <h2 className={styles.studentPass3}>
                      <p className={styles.dialysisPass}>Dialysis Pass</p>
                    </h2>
                    <div className={styles.affordableTravelPasses2}>
                      Free bus travel for dialysis patients, ensuring their
                      regular treatment
                    </div>
                  </div>
                  <div className={styles.lineSeparater2}>
                    <img
                      className={styles.lineSeparatorIcon2}
                      alt=""
                      src="/line-separator.svg"
                    />
                  </div>
                  <div className={styles.buttonHolder2}>
                    <div className={styles.buttons2}>
                      <a className={styles.searchBus2}>Apply</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.busPassOthersCards3}>
                <div className={styles.imageWeb3}>
                  <img
                    className={styles.picturesIcon6}
                    alt=""
                    src="/pictures@2x.png"
                  />
                </div>
                <div className={styles.imageMobile3}>
                  <img
                    className={styles.picturesIcon7}
                    alt=""
                    src="/pictures-1@2x.png"
                  />
                </div>
                <div className={styles.cardTextHolder3}>
                  <div className={styles.texts3}>
                    <h2 className={styles.studentPass4}>
                      <p className={styles.divyang}>Divyang</p>
                    </h2>
                    <div
                      className={styles.affordableTravelPasses3}
                    >{`Lorem ipsum dolor sit amet, adipiscing elit. sed do eiusmod tempor incididunt ut.  `}</div>
                  </div>
                  <div className={styles.lineSeparater3}>
                    <img
                      className={styles.lineSeparatorIcon3}
                      alt=""
                      src="/line-separator.svg"
                    />
                  </div>
                  <div className={styles.buttonHolder3}>
                    <div className={styles.buttons3}>
                      <div className={styles.searchBus3}>Apply</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.busPassOthersCards4}>
                <div className={styles.imageWeb4}>
                  <img
                    className={styles.picturesIcon8}
                    alt=""
                    src="/pictures@2x.png"
                  />
                </div>
                <div className={styles.imageMobile4}>
                  <img
                    className={styles.picturesIcon9}
                    alt=""
                    src="/pictures-1@2x.png"
                  />
                </div>
                <div className={styles.cardTextHolder4}>
                  <div className={styles.texts4}>
                    <h2 className={styles.studentPass5}>
                      <p className={styles.monthlySeasonTickets}>
                        Monthly Season Tickets
                      </p>
                    </h2>
                    <div
                      className={styles.affordableTravelPasses4}
                    >{`Lorem ipsum dolor sit amet, adipiscing elit. sed do eiusmod tempor incididunt ut.  `}</div>
                  </div>
                  <div className={styles.lineSeparater4}>
                    <img
                      className={styles.lineSeparatorIcon4}
                      alt=""
                      src="/line-separator.svg"
                    />
                  </div>
                  <div className={styles.buttonHolder4}>
                    <div className={styles.buttons4}>
                      <div className={styles.searchBus4}>Apply</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.busPassOthersCards5}>
                <div className={styles.imageWeb5}>
                  <img
                    className={styles.picturesIcon10}
                    alt=""
                    src="/pictures@2x.png"
                  />
                </div>
                <div className={styles.imageMobile5}>
                  <img
                    className={styles.picturesIcon11}
                    alt=""
                    src="/pictures-1@2x.png"
                  />
                </div>
                <div className={styles.cardTextHolder5}>
                  <div className={styles.texts5}>
                    <h2 className={styles.studentPass6}>
                      <p className={styles.journalists}>Journalists</p>
                    </h2>
                    <div
                      className={styles.affordableTravelPasses5}
                    >{`Lorem ipsum dolor sit amet, adipiscing elit. sed do eiusmod tempor incididunt ut.  `}</div>
                  </div>
                  <div className={styles.lineSeparater5}>
                    <img
                      className={styles.lineSeparatorIcon5}
                      alt=""
                      src="/line-separator.svg"
                    />
                  </div>
                  <div className={styles.buttonHolder5}>
                    <div className={styles.buttons5}>
                      <a className={styles.searchBus5}>Apply</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.busPassOthersCards6}>
                <div className={styles.imageWeb6}>
                  <img
                    className={styles.picturesIcon12}
                    alt=""
                    src="/pictures@2x.png"
                  />
                </div>
                <div className={styles.imageMobile6}>
                  <img
                    className={styles.picturesIcon13}
                    alt=""
                    src="/pictures-1@2x.png"
                  />
                </div>
                <div className={styles.cardTextHolder6}>
                  <div className={styles.texts6}>
                    <h2 className={styles.studentPass7}>
                      <p className={styles.freedomFighters}>Freedom Fighters</p>
                    </h2>
                    <div
                      className={styles.affordableTravelPasses6}
                    >{`Lorem ipsum dolor sit amet, adipiscing elit. sed do eiusmod tempor incididunt ut.  `}</div>
                  </div>
                  <div className={styles.lineSeparater6}>
                    <img
                      className={styles.lineSeparatorIcon6}
                      alt=""
                      src="/line-separator.svg"
                    />
                  </div>
                  <div className={styles.buttonHolder6}>
                    <div className={styles.buttons6}>
                      <div className={styles.searchBus6}>Apply</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.busPassOthersCards7}>
                <div className={styles.imageWeb7}>
                  <img
                    className={styles.picturesIcon14}
                    alt=""
                    src="/pictures@2x.png"
                  />
                </div>
                <div className={styles.imageMobile7}>
                  <img
                    className={styles.picturesIcon15}
                    alt=""
                    src="/pictures-1@2x.png"
                  />
                </div>
                <div className={styles.cardTextHolder7}>
                  <div className={styles.texts7}>
                    <h2 className={styles.studentPass8}>
                      MLAs, MPs, MLCs and Spouses
                    </h2>
                    <div
                      className={styles.affordableTravelPasses7}
                    >{`Lorem ipsum dolor sit amet, adipiscing elit. sed do eiusmod tempor incididunt ut. `}</div>
                  </div>
                  <div className={styles.lineSeparater7}>
                    <img
                      className={styles.lineSeparatorIcon7}
                      alt=""
                      src="/line-separator.svg"
                    />
                  </div>
                  <div className={styles.buttonHolder7}>
                    <div className={styles.buttons7}>
                      <div className={styles.searchBus7}>Apply</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className={styles.overlayBg} />
      </main>
    </div>
  );
};

export default BusPassInformationOverlay;
