import { FunctionComponent } from "react";
import Name1 from "./Name1";
import styles from "./Details3.module.css";

export type Details3Type = {
  className?: string;
};

const Details3: FunctionComponent<Details3Type> = ({ className = "" }) => {
  return (
    <div className={[styles.details, className].join(" ")}>
      <div className={styles.title}>Document Upload</div>
      <div className={styles.passengerName}>
        <div className={styles.name}>
          <Name1
            labelName="Upload applicant’s passport size photo*:"
            enterTextHerePlaceholder="Enter your full address"
            propFlex="1"
            propMinWidth="120px"
            propWidth="unset"
          />
          <button className={styles.buttons}>
            <div className={styles.searchBus}>Choose file</div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Details3;
