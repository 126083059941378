import { FunctionComponent } from "react";
import styles from "./MiniBanner.module.css";

export type MiniBannerType = {
  className?: string;
};

const MiniBanner: FunctionComponent<MiniBannerType> = ({ className = "" }) => {
  return (
    <div className={[styles.miniBanner, className].join(" ")}>
      <img
        className={styles.bgBannerImage}
        alt=""
        src="/bg-banner-image@2x.png"
      />
      <div className={styles.bannerContent}>
        <button className={styles.backButton}>
          <div className={styles.back}>
            <div className={styles.arrowIconWrapper}>
              <img className={styles.arrowIcon} alt="" src="/arrow-icon.svg" />
            </div>
            <a className={styles.back1}>Back</a>
          </div>
          <img
            className={styles.backButtonChild}
            alt=""
            src="/rectangle-41191.svg"
          />
        </button>
      </div>
      <div className={styles.textAndButton}>
        <img
          className={styles.tsrtcLogoIcon}
          loading="lazy"
          alt=""
          src="/tsrtc-logo@2x.png"
        />
        <div className={styles.applicationTitleContainer}>
          <h2 className={styles.applicationForCity}>
            Application for City General Commuter Pass
          </h2>
        </div>
      </div>
    </div>
  );
};

export default MiniBanner;
