import { FunctionComponent } from "react";
import styles from "./PassengerDetails3.module.css";

export type PassengerDetails3Type = {
  className?: string;
};

const PassengerDetails3: FunctionComponent<PassengerDetails3Type> = ({
  className = "",
}) => {
  return (
    <div className={[styles.passengerDetails, className].join(" ")}>
      <div className={styles.title}>
        <div className={styles.applicantDetails}>Applicant Details</div>
        <img
          className={styles.titleChild}
          alt=""
          src="/vector-234251220-1.svg"
        />
      </div>
      <div className={styles.applicantForm}>
        <div className={styles.details}>
          <div className={styles.nameField}>
            <div className={styles.name}>
              <div className={styles.name1}>Name</div>
              <div className={styles.radhikaChandanaChandana}>
                Radhika Chandana Chandana
              </div>
            </div>
          </div>
        </div>
        <div className={styles.age}>
          <div className={styles.gender}>Gender</div>
          <div className={styles.female}>Female</div>
        </div>
        <div className={styles.mandalField}>
          <div className={styles.name2}>
            <div className={styles.dateOfBirth}>Date of Birth</div>
            <div className={styles.dateOfBirth1}>19/05/1962</div>
          </div>
        </div>
        <div className={styles.villageCityField}>
          <div className={styles.name3}>
            <div
              className={styles.aadharCardNumber}
            >{`Aadhar Card Number: `}</div>
            <div className={styles.aadharValue}>3977 0234 8743</div>
          </div>
        </div>
        <div className={styles.pincodeField}>
          <div className={styles.name4}>
            <div className={styles.fatherguardiansName}>
              Father/Guardian’s Name:
            </div>
            <div className={styles.ravindraChandrashekar}>
              Ravindra Chandrashekar
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PassengerDetails3;
