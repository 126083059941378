import { FunctionComponent } from "react";
import styles from "./WelcomeCardContainer.module.css";

export type WelcomeCardContainerType = {
  className?: string;
};

const WelcomeCardContainer: FunctionComponent<WelcomeCardContainerType> = ({
  className = "",
}) => {
  return (
    <section className={[styles.welcomeCardContainer, className].join(" ")}>
      <div className={styles.welcomeCard}>
        <img className={styles.imageIcon} alt="" src="/image@2x.png" />
        <div className={styles.text}>
          <h1 className={styles.title}>
            Stay on the move with easy pass applications and renewals!
          </h1>
          <div className={styles.description}>What are you here for?</div>
        </div>
        <div className={styles.passAndTrackBanner}>
          <div className={styles.passApplication}>
            <div className={styles.container}>
              <h2 className={styles.passApplications}>Pass Applications</h2>
              <div
                className={styles.tgsrtcOffersA}
              >{`TGSRTC offers a range of bus passes to cater to various passenger needs, apply now! `}</div>
            </div>
            <div className={styles.buttons}>
              <div className={styles.searchBus}>Apply for a Pass</div>
            </div>
          </div>
          <img
            className={styles.separatorIcon}
            loading="lazy"
            alt=""
            src="/separator.svg"
          />
          <div className={styles.trackApplication}>
            <div className={styles.trackApplicationsParent}>
              <h2 className={styles.trackApplications}>Track Applications</h2>
              <div className={styles.alreadyAppliedFor}>
                Already applied for a bus pass? Check your application status
                here.
              </div>
            </div>
            <button className={styles.buttons1}>
              <div className={styles.searchBus1}>Track my Application</div>
            </button>
          </div>
        </div>
        <div className={styles.popUp}>
          <div className={styles.titleBar}>
            <input
              className={styles.title1}
              placeholder="Before you apply, you will need"
              type="text"
            />
            <div className={styles.closeButtonContainer}>
              <img
                className={styles.actionIcon}
                alt=""
                src="/action-icon.svg"
              />
            </div>
          </div>
          <div className={styles.popupContent}>
            <div className={styles.popupInformation}>
              <div className={styles.information}>
                <div className={styles.textBox}>
                  <div className={styles.text1}>
                    <div className={styles.passportSizePhotoContainer}>
                      <ol className={styles.passportSizePhotoOfTheApp}>
                        <li>Passport Size Photo of the applicant</li>
                      </ol>
                    </div>
                    <div className={styles.pleaseUploadIndividualContainer}>
                      <ul className={styles.pleaseUploadIndividualPassp}>
                        <li>
                          Please upload individual passport photo. Selfies or
                          group photos are not accepted.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className={styles.additionalInfo}>
                    <ol className={styles.yourAadhaarNumber}>
                      <li>Your Aadhaar Number</li>
                    </ol>
                  </div>
                </div>
                <div className={styles.text2}>
                  <p className={styles.note}>
                    <b>{`Note: `}</b>
                  </p>
                  <p className={styles.startingPriceOfCityGeneral}>
                    <span>{`Starting price of City General Commuter pass is `}</span>
                    <span className={styles.rs1150}>Rs. 1150</span>
                  </p>
                </div>
              </div>
              <div className={styles.passengerContainerWrapper}>
                <div className={styles.passengerContainer}>
                  <div className={styles.passengerImageContainer}>
                    <div className={styles.passengerImages}>
                      <div className={styles.imagePlaceholder} />
                      <img
                        className={styles.imagePlaceholderIcon}
                        loading="lazy"
                        alt=""
                        src="/vector1.svg"
                      />
                    </div>
                  </div>
                  <div className={styles.imageInfo}>
                    <div className={styles.imageName}>Sample photo</div>
                    <div className={styles.imageDescription}>
                      <span>{`Image size should be below `}</span>
                      <b>100 KB</b>
                    </div>
                  </div>
                  <button className={styles.buttons2}>
                    <div className={styles.searchBus2}>
                      Proceed to Application
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WelcomeCardContainer;
