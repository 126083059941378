import { FunctionComponent } from "react";
import Header from "../components/Header";
import PassApplication from "../components/PassApplication";
import FrameComponent from "../components/FrameComponent";
import PickupAndDropPoints from "../components/PickupAndDropPoints";
import Cards from "../components/Cards";
import BusPassOthersCards1 from "../components/BusPassOthersCards1";
import BusPassOthersCards from "../components/BusPassOthersCards";
import styles from "./BusPassSelectionStateOverl.module.css";

const BusPassSelectionStateOverl: FunctionComponent = () => {
  return (
    <div className={styles.busPassSelectionStateOverl}>
      <Header />
      <main className={styles.welcomeCardParent}>
        <section className={styles.welcomeCard}>
          <img className={styles.imageIcon} alt="" src="/image@2x.png" />
          <div className={styles.text}>
            <h1 className={styles.title}>
              Stay on the move with easy pass applications and renewals!
            </h1>
            <div className={styles.description}>What are you here for?</div>
          </div>
          <div className={styles.passAndTrackBanner}>
            <PassApplication
              passApplications="Pass Applications"
              tGSRTCOffersARangeOfBusPa="TGSRTC offers a range of bus passes to cater to various passenger needs, apply now! "
              searchBus="Apply for a Pass"
            />
            <img
              className={styles.separatorIcon}
              loading="lazy"
              alt=""
              src="/separator.svg"
            />
            <PassApplication
              passApplications="Track Applications"
              tGSRTCOffersARangeOfBusPa="Already applied for a bus pass? Check your application status here."
              searchBus="Track my Application"
              propMinWidth="369px"
            />
          </div>
          <div className={styles.popUp}>
            <div className={styles.title1}>
              <h3 className={styles.title2}>
                Select the type of general commuter pass you want to opt for
              </h3>
              <img
                className={styles.actionIcon}
                loading="lazy"
                alt=""
                src="/action-icon.svg"
              />
            </div>
            <div className={styles.optionCards}>
              <div className={styles.card1}>
                <FrameComponent cityGeneralCommuterPass="City General Commuter Pass" />
                <FrameComponent cityGeneralCommuterPass="District General Commuter Pass" />
              </div>
            </div>
            <PickupAndDropPoints />
          </div>
        </section>
        <section className={styles.busPassContainer}>
          <div className={styles.popularPassesContainer}>
            <div className={styles.frameParent}>
              <div className={styles.headingWrapper}>
                <h2 className={styles.heading}>Popular Pass Options</h2>
              </div>
              <footer className={styles.busPassPopularCards}>
                <Cards
                  group1000013608="/group-1000013608@2x.png"
                  generalBusTicket="General Bus Ticket"
                  reliableDailyTravelForEve=" Reliable daily travel for everyone. Easy and economical bus rides."
                />
                <Cards
                  group1000013608="/group-1000013608-11@2x.png"
                  generalBusTicket="Monthly Season Ticket"
                  reliableDailyTravelForEve="Monthly passes for frequent travellers, 30 days journey with only 20 days fare."
                />
                <Cards
                  group1000013608="/group-1000013608-21@2x.png"
                  generalBusTicket="Pushpak Pass"
                  reliableDailyTravelForEve="Lorem ipsum dolor sit amet, adipiscing elit. sed do eiusmod tempor incididunt ut.  adipiscing elit. sed do eiusmod."
                />
              </footer>
            </div>
          </div>
          <div className={styles.busPassOtherPassesContai}>
            <h2 className={styles.otherAvailablePasses}>
              Other Available Passes
            </h2>
            <div className={styles.busPassOtherCards}>
              <BusPassOthersCards1
                studentPass="Student Pass"
                affordableTravelPassesFor="Affordable travel passes for students. Convenient and budget-friendly commuting."
              />
              <BusPassOthersCards1
                studentPass="NGO Pass"
                affordableTravelPassesFor="Special bus passes supporting NGO workers' daily transportation needs."
              />
              <BusPassOthersCards1
                studentPass="Dialysis Pass"
                affordableTravelPassesFor="Free bus travel for dialysis patients, ensuring their regular treatment"
              />
              <BusPassOthersCards1
                studentPass="Divyang"
                affordableTravelPassesFor="Lorem ipsum dolor sit amet, adipiscing elit. sed do eiusmod tempor incididunt ut.  "
              />
              <BusPassOthersCards1
                studentPass="Monthly Season Tickets"
                affordableTravelPassesFor="Lorem ipsum dolor sit amet, adipiscing elit. sed do eiusmod tempor incididunt ut.  "
              />
              <BusPassOthersCards1
                studentPass="Journalists"
                affordableTravelPassesFor="Lorem ipsum dolor sit amet, adipiscing elit. sed do eiusmod tempor incididunt ut.  "
              />
              <BusPassOthersCards1
                studentPass="Freedom Fighters"
                affordableTravelPassesFor="Lorem ipsum dolor sit amet, adipiscing elit. sed do eiusmod tempor incididunt ut.  "
              />
              <BusPassOthersCards />
            </div>
          </div>
        </section>
        <section className={styles.overlayBg} />
      </main>
    </div>
  );
};

export default BusPassSelectionStateOverl;
