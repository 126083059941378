import { FunctionComponent } from "react";
import styles from "./PassengerDetails1.module.css";

export type PassengerDetails1Type = {
  className?: string;
};

const PassengerDetails1: FunctionComponent<PassengerDetails1Type> = ({
  className = "",
}) => {
  return (
    <div className={[styles.passengerDetails, className].join(" ")}>
      <div className={styles.title}>
        <div className={styles.documents}>Documents</div>
        <img
          className={styles.titleChild}
          alt=""
          src="/vector-234251220-3.svg"
        />
      </div>
      <div className={styles.details}>
        <div className={styles.photoContainer}>
          <div className={styles.name}>
            <div
              className={styles.applicantsPassportSize}
            >{`Applicant’s passport size photo*: `}</div>
            <div className={styles.tourCard}>
              <div className={styles.tourPackages}>
                <div className={styles.tourDetail}>
                  <div className={styles.placeName}>
                    <img
                      className={styles.chowmahallaPalaceIcon}
                      loading="lazy"
                      alt=""
                      src="/chowmahalla-palace@2x.png"
                    />
                  </div>
                  <div className={styles.imageTab}>
                    <div className={styles.img23499200jpg}>
                      IMG 23499200.jpg
                    </div>
                    <img
                      className={styles.actionIcon}
                      alt=""
                      src="/action-icon.svg"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PassengerDetails1;
