import { FunctionComponent } from "react";
import Name2 from "./Name2";
import styles from "./Details1.module.css";

export type Details1Type = {
  className?: string;
};

const Details1: FunctionComponent<Details1Type> = ({ className = "" }) => {
  return (
    <div className={[styles.details, className].join(" ")}>
      <div className={styles.title}>Address Details</div>
      <div className={styles.passengerName}>
        <div className={styles.name}>
          <Name2 enterTextHerePlaceholder="18A, Sankalp Colony, Khairatabad, Hyderabad." />
          <div className={styles.name1}>
            <div className={styles.labelName}>District*</div>
            <div className={styles.enterTextHereParent}>
              <div className={styles.enterTextHere}>Hyderabad</div>
              <img className={styles.icons} alt="" src="/icons2.svg" />
            </div>
          </div>
          <div className={styles.name2}>
            <div className={styles.labelName1}>Mandal*</div>
            <div className={styles.enterTextHereGroup}>
              <div className={styles.enterTextHere1}>Hyderabad</div>
              <img className={styles.icons1} alt="" src="/icons2.svg" />
            </div>
          </div>
          <div className={styles.name3}>
            <div className={styles.labelName2}>Village/City*</div>
            <div className={styles.enterTextHereContainer}>
              <div className={styles.enterTextHere2}>Hyderabad</div>
              <img className={styles.icons2} alt="" src="/icons2.svg" />
            </div>
          </div>
          <div className={styles.name4}>
            <div className={styles.labelName3}>Pincode*</div>
            <div className={styles.enterTextHereWrapper}>
              <div className={styles.enterTextHere3}>500004</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Details1;
