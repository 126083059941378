import { FunctionComponent } from "react";
import Header2 from "../components/Header2";
import MiniBanner from "../components/MiniBanner";
import Details5 from "../components/Details5";
import Details4 from "../components/Details4";
import Details3 from "../components/Details3";
import Name1 from "../components/Name1";
import styles from "./BusPassFormField.module.css";

const BusPassFormField: FunctionComponent = () => {
  return (
    <div className={styles.busPassFormField}>
      <Header2 />
      <MiniBanner />
      <main className={styles.passengerDetailsContainer}>
        <section className={styles.passengerDetails}>
          <div className={styles.details}>
            <div className={styles.title}>Pass Type Information</div>
            <div className={styles.passengerName}>
              <div className={styles.name}>
                <div className={styles.name1}>
                  <div className={styles.labelName}>City</div>
                  <div className={styles.enterTextHereParent}>
                    <div className={styles.enterTextHere}>Hyderabad</div>
                    <img className={styles.icons} alt="" src="/icons1.svg" />
                  </div>
                </div>
                <div className={styles.name2}>
                  <div className={styles.labelName1}>Pass type*</div>
                  <div className={styles.enterTextHereGroup}>
                    <a className={styles.enterTextHere1}>Metro Express</a>
                    <img className={styles.icons1} alt="" src="/icons1.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Details5 />
          <Details4 />
          <Details3 />
          <div className={styles.details1}>
            <div className={styles.title1}>
              <div className={styles.title2}>Contact Details</div>
              <div className={styles.theDetailsOf}>
                The details of the status of your application will be sent to
                the details shared below:
              </div>
            </div>
            <div className={styles.passengerName1}>
              <div className={styles.name3}>
                <Name1
                  labelName="Mobile Number *"
                  enterTextHerePlaceholder="Enter your 10 digit mobile number"
                  propFlex="1"
                  propMinWidth="120px"
                  propWidth="unset"
                />
                <Name1
                  labelName="Email ID"
                  enterTextHerePlaceholder="Enter a valid email ID"
                  propFlex="1"
                  propMinWidth="120px"
                  propWidth="unset"
                />
              </div>
            </div>
          </div>
          <footer className={styles.bottomButton}>
            <div className={styles.buttons}>
              <div className={styles.searchBus}>Confirm Details</div>
            </div>
          </footer>
        </section>
      </main>
    </div>
  );
};

export default BusPassFormField;
