import { FunctionComponent } from "react";
import styles from "./Name4.module.css";

export type Name4Type = {
  className?: string;
  labelName?: string;
  enterTextHere?: string;
};

const Name4: FunctionComponent<Name4Type> = ({
  className = "",
  labelName,
  enterTextHere,
}) => {
  return (
    <div className={[styles.name, className].join(" ")}>
      <div className={styles.labelName}>{labelName}</div>
      <div className={styles.enterTextHereParent}>
        <div className={styles.enterTextHere}>{enterTextHere}</div>
        <img className={styles.icons} alt="" src="/icons1.svg" />
      </div>
    </div>
  );
};

export default Name4;
