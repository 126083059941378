import { FunctionComponent } from "react";
import Name2 from "./Name2";
import Name4 from "./Name4";
import Name1 from "./Name1";
import styles from "./Details4.module.css";

export type Details4Type = {
  className?: string;
};

const Details4: FunctionComponent<Details4Type> = ({ className = "" }) => {
  return (
    <div className={[styles.details, className].join(" ")}>
      <div className={styles.title}>Address Details</div>
      <div className={styles.passengerName}>
        <div className={styles.name}>
          <Name2 enterTextHerePlaceholder="Enter your full address" />
          <Name4 labelName="District*" enterTextHere="Select your district" />
          <Name4 labelName="Mandal*" enterTextHere="Select your Mandal" />
          <Name4
            labelName="Village/City*"
            enterTextHere="Select your village/city"
          />
          <Name1
            labelName="Pincode*"
            enterTextHerePlaceholder="Enter your pincode"
            propFlex="unset"
            propMinWidth="unset"
            propWidth="300px"
          />
        </div>
      </div>
    </div>
  );
};

export default Details4;
