import { FunctionComponent } from "react";
import styles from "./PassengerDetails.module.css";

export type PassengerDetailsType = {
  className?: string;
};

const PassengerDetails: FunctionComponent<PassengerDetailsType> = ({
  className = "",
}) => {
  return (
    <div className={[styles.passengerDetails, className].join(" ")}>
      <div className={styles.title}>
        <div className={styles.contactDetails}>Contact Details</div>
        <img
          className={styles.titleChild}
          alt=""
          src="/vector-234251220-4.svg"
        />
      </div>
      <div className={styles.details}>
        <div className={styles.contactForm}>
          <div className={styles.name}>
            <div className={styles.mobileNumber}>Mobile Number:</div>
            <div className={styles.emailValue}>9883647892</div>
          </div>
        </div>
        <div className={styles.contactForm1}>
          <div className={styles.name1}>
            <div className={styles.emailId}>Email ID:</div>
            <div className={styles.radhikagmailcom}>radhika@gmail.com</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PassengerDetails;
